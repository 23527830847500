<template>
    <div class="">
        <div class="container-fluid px-0 mx-0" style="background-color: #FFFDEE">
           <img :src="'https://adm-estusae.makutapro.id/storage/images/'+detail.img_layout" class="img-fluid" width="100%" alt="">
        </div>
        <div class="container-fluid" style="background-color: #FFFDEE">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-lg-1"></div>
                    <div class="col-12 col-lg-5 py-3 py-lg-5  mx-lg-0">
                        <div class="row py-3 py-lg-5">
                            <div class="col-12 text-start">
                                <h2 class="garden">{{detail.name}} </h2>
                                <h1 class="tH1"><sup class="tSmall">TYPE</sup> {{detail.type}} </h1>
                                <h4 class="garis mt-0"></h4>
                                <div class="row textSize">
                                    
                                    <div class="col-6">
                                        <p><strong>LB</strong> {{detail.lb}}  m2</p>
                                    </div>
                                    <div class="col-6">
                                        <p><strong>LT</strong> {{detail.lt}}  m2</p>
                                    </div>
                                </div>
                                <h4 class="garis mt-0"></h4>
                                <div class="row textFac" style="line-height: 28px">
                                    <div class="col-2">
                                        <p>KAMAR TIDUR</p>
                                    </div>
                                    <div class="col-2">
                                        <h1 class="text-start   ms-lg-0 kamar"><strong>{{detail.bedroom}} </strong></h1>
                                    </div>
                                    <div class="col-2 ms-3 ms-lg-0">
                                        <p >KAMAR MANDI</p>
                                    </div>
                                    <div class="col-2">
                                        <h1 class="text-start  ms-lg-0 kamar"><strong>{{detail.bathroom}}</strong></h1>
                                    </div>
                                    <div class="col-4"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-1"></div>
                   <div class="col-12 col-lg-3 py-lg-5 py-0 ps-2 ps-lg-5">
                        <span class="pt-4 tlist text-start" style="line-height: 14px" v-html="detail.facilities"></span>
                    </div>
                </div>
            </div>
        </div>
        <div class="container-fluid px-0 mx-0 py-3" style="background-color: #FFFFFF">
            <div class="container py-3 pb-4">
                <div class="row pt-3">
                        <h2 class="denah text-center">DENAH RUANGAN</h2>
                   <div class="col-2"></div>
                    <div class="col-8">
                            <img :src="'https://adm-estusae.makutapro.id/storage/images/'+detail.img_facade" class="img-fluid pb-3" width="100%" alt="">
                    </div>
                    <div class="col-2"></div>
                </div>
            </div>
            <div class="container pb-5" style="border-top: 3px solid #F47F25">
                <div class="row py-3">
                    <div class="col-12">
                        <h2 class="spek pt-3 text-center">SPESIFIKASI</h2>
                    </div>
                </div>
                <div class="row py-3">
                    <div class="col-12 col-lg-12">
                        <h5 class="mt-2 tlist" style="line-height: 30px" v-html="detail.specification"></h5>
                    </div>
                 </div>
            </div>
        </div>

        <div class="container-fluid  py-5" style="background-color: #2E2D2D">
        <div class="container py-2 text-center">
            <div class="row">
                <div class="col-12 textunit">
                    <h1>UNIT LAIN</h1>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-lg-4 my-3 my-lg-0" v-for="isi in isidetail" :key="isi.index" @click="HandleSubmit()">
                    <router-link :to="{ name: 'detail_units', params: { slug: isi.slug}}" class="nav-link px-0 mt-0">
                        <div class="">
                            <img :src="'https://adm-estusae.makutapro.id/storage/images/'+isi.img_layout" class="card-img-top" alt="" >
                            <div style="background-color:#FFFDEE">
                                <div class="ps-2 pt-1 pita " style="width:220px; height:32px;"><p class="pita1 ">&nbsp;Harga Mulai&nbsp;<span class="pita2"><strong>{{isi.price}}</strong>&nbsp;Jt-an</span></p></div>
                            </div>
                            <div class="card-body bgCard mx-0 my-0">
                                <h5 class="card-title textCard" >{{isi.name}}</h5>
                            </div>
                        </div>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
    </div>
</template>

<style scoped>
.bgCard {
    background-color: #FFFDEE;
}

.textCard {
    font-family: Manuale;
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0.25em;
    color: #724603;

}

.textunit {
    font-family: Manuale;
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0.35em;
    color: #FFFDEE;
}

.pya {
 padding-top: 84px;
}

.garis{
    border-bottom: 2px solid #F47F25;
    margin-right: 50px;
; 
    /* opacity:50%; */
}

.tSmall {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    align-items: top;
    /* text-outline: 0 0 #000;  */
    letter-spacing: 0.335em;
    color: #2E2D2D;
}

.tH1 {
    font-family: 'Montserrat';
    /* font-style: normal; */
    font-weight: 900;
    /* font-size: 90px; */
    /* line-height: 39px; */
    /* or 43% */
    /* display: flex;
    align-items: center; */
    letter-spacing: 0.07em;
    color: #2E2D2D;
}

.kamar{
    font-family: 'Montserrat';
    font-weight: 900;
    font-size: 57px;
    letter-spacing: 0.07em;
    color: #2E2D2D;
}
th {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0.13em;

    color: #000000;

}
td {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.13em;
    color: #000000;

}

.textFac {
    font-family: Montserrat;
font-style: normal;
font-weight: 600;
/* font-size: 70px; */
line-height: 39px;
letter-spacing: 0.13em;

color: #32312D;

}

.textAkhir {
    font-family: Montserrat;
font-style: normal;
font-weight: 500;
/* font-size: 18px; */
line-height: 29px;
/* or 164% */

letter-spacing: 0.06em;

color: #2E2D2D;
}

.textSize {
    font-family: Montserrat;
font-style: normal;
font-weight: normal;
/* font-size: 24px; */
line-height: 39px;
/* or 162% */

letter-spacing: 0.13em;

color: #2E2D2D;
}

.tlist {
    font-family: Montserrat;
font-style: normal;
font-weight: normal;
font-size: 19px;
letter-spacing: 0.13em;

color: #000000;
}

.tlist2 {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    letter-spacing: 0.13em;

    color: #000000;

}
.tlist3 {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    letter-spacing: 0.13em;
    color: #000000;

}

.textresi {
    font-family: Mansory SemiBold;
    font-style: normal;
    font-weight: 600;
    line-height: 39px;
    align-items: center;
    text-align: center;
    letter-spacing: 0.35em;
    color: #000000;

}
.garden {
    font-family: Manuale;
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0.335em;
    color: #CA7A02;

}
.denah {
    font-family: Manuale;
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0.335em;
    color: #CA7A02;

}
.spek {
    font-family: Manuale;
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0.335em;
    color: #CA7A02;

}
</style>

<script>
export default {
     name: 'detail_units',
     data(){
            return{
                detail:[], 
                isidetail:[], 
            }
        },
    props:['slug'],
    mounted () {
    window.scrollTo(0, 0)
    },
    created(){
        window.scrollTo(0, 0)
        this.$axios.get('https://adm-estusae.makutapro.id/api/detailunit?slug=' + this.$route.params.slug)
            .then((response) => {
            this.detail = response.data.data[0] 
            }),
        this.$axios.get('https://adm-estusae.makutapro.id/api/isidetailunit')
        .then((response) => {
            // console.log(response)
                this.isidetail = response.data.data
            })
        },
    methods: {
        HandleSubmit(){
            window.scrollTo(0, 0)
            this.$axios.get('https://adm-estusae.makutapro.id/api/detailunit?slug=' + this.$route.params.slug)
            .then((response) => {
            this.detail = response.data.data[0] 
            })
        }
    }
        
}
</script>